/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

import { GetPercentageList } from './../../../src/api/dashboard';
import "./Header.css";

class Header extends React.Component {
  state = {
    data: [{"name":"-","balance":'-',"profit":'-',"growthRate":'-',"openPosition":'-'}],
    dataLength: 3
  };

  componentWillMount() {
    this.GetPercentageList()
  }
  async GetPercentageList() {
    const res =  await GetPercentageList();
    //console.log(res.data);
    this.setState({
      data: res.data,
    });
    if ( this.state.data.length > 4) {
      this.setState({
        dataLength: parseInt(12 / this.state.data.length),
      });
    }else {
      this.setState({
        dataLength: 3,
      });
    }
  }

  render() {
    return (
      <>
        <div className="header bg-gradient-primary pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row>
                {this.state.data.map((item,index) => {
                  return <Col lg="6" xl="3" key={index} className="marginBottom10px">

                      <Card className="card-stats mb-4 mb-xl-0">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                  tag="h5"
                                  className="text-uppercase text-muted mb-0"
                              >
                                {item.name}
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                            {item.balance}
                          </span>
                            </div>
                            <Col className="col">
                              <p className="text-sm">
                                <span className="text-nowrap">Holding {item.percent}%</span>
                              </p>
                            </Col>
                          </Row>
                          <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          {item.growthRate > 0?<i className="fa fa-arrow-up text-success" />:<i className="fa fa-arrow-down text-warning" />}&nbsp;&nbsp;{item.growthRate}%
                        </span>{" "}
                            <span className="text-nowrap"><i className=" ni ni-diamond" /> {item.profit}</span>
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                })}
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default Header;
