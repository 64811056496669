import Ax from './../api/base.request.js';
import config from './../env.config';
import React from "react";

const API = config.DEMO_API;

export function getbalance() {
    return Ax.get(`${API}/getbalance`).then(res => res);
}

export function GetPercentageList() {
    return Ax.get(`${API}/get_percentage_list`).then(res => res);
}

export function GetRecordBalance2Hour() {
    return Ax.get(`${API}/getrecordbalance2hour`).then(res => res);
}

export function GetRecordBalance1Day() {
    return Ax.get(`${API}/getrecordbalance1day`).then(res => res);
}

export function GetProfitTransactionList() {
    return Ax.get(`${API}/profit_transaction_list`).then(res => res);
}

export function getMonitorInfoList(name) {
    return Ax.get(`${API}/get_monitor_info_list?tradingPair=${name}`).then(res => res);
}

export function klineList(coinType,period) {
    return Ax.get(`${API}/kline_list?coinType=${coinType}&period=${period}`).then(res => res);
}

export function monitorSymbol() {
    return Ax.get(`${API}/monitor_symbol`).then(res => res);
}


export function monitorInfo(coinType) {
    return Ax.get(`${API}/monitor_info?coinType=${coinType}`).then(res => res);
}


export function HandlingAlerts(par) {
    return Ax.post(`${API}/handling_alerts`,par).then(res => res);
}
