import axios from 'axios';

window.cancelTokenList = [];

const createRequest = () => {
  const service = axios.create({
    timeout: 15000
  });
  service.interceptors.request.use((config) => {
    // config.headers.token = ssoAuth.token;
    // 全局CancelToken
    const source = axios.CancelToken.source();
    window.cancelTokenList.push(source);
    config.cancelToken = source.token;
    return config;
  }, (error) => {
    Promise.reject(error);
  });

  service.interceptors.response.use(
    (response) => {
      // 处理全局CancelToken List
      window.cancelTokenList = window.cancelTokenList
        .filter(x => x.token !== response.config.cancelToken);
      // 各种返回状况处理
      const res = response && response.data;
      return res;
    },
    (error) => {
    }
  );
  return service;
};
export default createRequest;
