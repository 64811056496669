/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Pie } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Modal,
  Input,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample6,
  chartExample10,
  colors
} from "variables/charts.jsx";

import Header from "components/Headers/Header.jsx";
import { GetPercentageList, GetRecordBalance2Hour,GetRecordBalance1Day,GetProfitTransactionList,getMonitorInfoList,HandlingAlerts } from './../../src/api/dashboard';
import { klineList, monitorInfo} from "../api/dashboard";

const colAll = [ 'default','info','secondary','success','danger','warning','primary'];
class Index extends React.Component {
  state = {
    activeNav: 1,
    chartExample1Data: {},
    pieData: {},
    pieButn: [],
    profit:{
      balance: '-',
      profit: '-',
      growthRate: '-',
    },
    profitTransactionList:[],
    monitorInfoList:[],
    select: null,
    select2Data:[],
    value1111: '',
    exampleModal: false,
    password: '',
    tradingPair: '',
    monitorInfoListOne:[],

    klineList: {
      labels: [],
      datasets: [{
        data: [],
      }],
    },
    activeNav_new: 2,
    coinType: '',
    symbol:[],
    period:'1day',
    profit_new:{
      cointName: '-',
      price: '-',
      increase:'-',
      totalIncrease: '-',
      alarmsNum: '-',
      symbol: '-'
    },
  };
  componentWillMount() {
    if (window.Chart) {
       //parseOptions(Chart, chartOptions());
    }
    this.GetPercentageList();
    this.GetRecordBalance1Day();
    this.GetprofitTransactionList();
    this.getMonitorInfoList('',1)
  }

  async GetprofitTransactionList(){
    const res =  await GetProfitTransactionList();
    this.setState({
      profitTransactionList:res.data
    });
  }

  async getMonitorInfoList(name,tag=0){
    const res =  await getMonitorInfoList(name);
    this.setState({
      monitorInfoList:res.data
    });
  }

  async monitorInfoListOne(name,tag=0){
    const res =  await getMonitorInfoList(name);
    this.setState({
      monitorInfoListOne:res.data
    });
  }
  async handleClick(value) {
    this.setState({
      exampleModal: true,
      tradingPair: value.tradingPair
    });
    this.monitorInfoListOne(value.tradingPair)
    this.klineList(value.coinType,"1day")
  }

  exampleModalClose() {
    this.setState({
      exampleModal: false
    });
  }
  async handleChangePass(event) {
    this.setState({
      password: event.target.value
    });
  }

  async GetPercentageList() {
    const res =  await GetPercentageList();
    const labels = [];
    const source = [];
    const  backgroundColor = [];
    const pieButn = [];
    res.data.map((item,index) => {
      labels.push(item.name);
      source.push(item.percent);
      backgroundColor.push(colors.theme[colAll[index]]);
      pieButn.push({name:item.name+' '+item.percent+'%',backgroundColor:colAll[index]})
    });
    this.setState({
      pieData:{'labels':labels,'datasets':[{'data':source,'backgroundColor':backgroundColor, label: "Dataset 1"}]},
      pieButn:pieButn,
      profit:res.profit,
    });
  }
  async GetRecordBalance2Hour() {
    const res =  await GetRecordBalance2Hour();
    const labels = [];
    const source = [];
    res.data.map((item,index) => {
      labels.push(item.timeTmp);
      source.push(item.balance);
    });
    this.setState({
      chartExample1Data:{'labels':labels,'datasets':[{'data':source,label: "balance2hour"}]},
    });
  }

  async GetRecordBalance1Day() {
    const res =  await GetRecordBalance1Day();
    const labels = [];
    const source = [];
    res.data.map((item,index) => {
      labels.push(item.timeTmp);
      source.push(item.balance);
    });
    this.setState({
      chartExample1Data:{'labels':labels,'datasets':[{'data':source,label: "balance1day"}]},
    });
  }

  async monitorInfo(coinType){
    const res =  await monitorInfo(coinType);
    this.setState({
      profit_new:res.data,
    });
  }
  async klineList(coinType,period) {
    this.setState({
      coinType:coinType,
    });
    this.monitorInfo(coinType);
    const res =  await klineList(coinType,period);
    const klineListData = {
      labels: [],
      datasets: [
        {
          label: 'MA 5  ',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgba(245,54,92,1)',
          borderColor: 'rgba(245,54,92,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 0,
          pointHitRadius: 10,
          data: [],
        },
        {
          label: 'MA 10 ',
          fill: false,
          backgroundColor: 'rgba(75,192,192,1)',
          borderColor: 'rgba(75,192,192,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 0,
          pointHitRadius: 10,
          data: []
        },
        {
          label: 'MA 30 ',
          fill: false,
          backgroundColor: 'rgba(244,245,247,1)',
          borderColor: 'rgba(244,245,247,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 0,
          pointHitRadius: 10,
          data: []
        },
        {
          label: 'PRICE ',
          fill: true,
          backgroundColor: 'rgba(19,184,133,0.05)',
          borderColor: 'rgba(19,184,133,0.05)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(19,184,133,0.05)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(19,184,133,0.05)',
          pointHoverBorderColor: 'rgba(19,184,133,0.05)',
          pointHoverBorderWidth: 2,
          pointRadius: 0,
          pointHitRadius: 10,
          data: []
        }
      ]
    };
    res.data.map((item,index) => {
      klineListData.labels.push(item.timeTmp);
      klineListData.datasets[0].data.push(item.klineLen5);
      klineListData.datasets[1].data.push(item.klineLen10);
      klineListData.datasets[2].data.push(item.klineLen30);
      klineListData.datasets[3].data.push(item.price);
    });
    this.setState({
      klineList:klineListData,
    });
    //this.location.reload();
  }

  // async handleChange(coinType) {
  //   //this.klineList(coinType,this.state.period)
  // }


  render() {
    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="8">
              <Card className="bg-gradient-default shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h2 className="text-uppercase text-light ls-1 mb-1">
                        <span className="mb-0" ><i className="ni ni-money-coins" />&nbsp;{this.state.profit.balance} &nbsp;&nbsp;&nbsp; <i className=" ni ni-diamond" />&nbsp;{this.state.profit.profit} &nbsp;&nbsp;&nbsp;<i className="fa fa-arrow-up" />&nbsp;{this.state.profit.growthRate}%</span>
                      </h2>
                      <h2 className="text-white mb-0">Total profit</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  {/* Chart */}
                  <div className="chart">
                    <Line
                      data={this.state.chartExample1Data}
                      options={chartExample1.options}
                      getDatasetAtEvent={e => console.log(e)}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="4">
              <Card className="shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                        Holding
                      </h6>
                    </div>
                  </Row>
                </CardHeader>
                <div className="chart" >
                  <Pie
                      data={this.state.pieData}
                      options={chartExample6.options}
                      className="chart-canvas"
                      id="chart-pie"
                  />
                </div>
              </Card>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="8">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">k-line monitoring</h3>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                  <tr>
                    <th scope="col">Coin-name</th>
                    <th scope="col">Trading-pair</th>
                    <th scope="col">Alert-Num</th>
                    <th scope="col">Increase</th>
                    <th scope="col">total-increase</th>
                    <th scope="col">status</th>
                    <th scope="col">alarms-Time</th>
                    <th scope="col">rank</th>
                  </tr>
                  </thead>
                  <tbody>
                  {this.state.monitorInfoList.map((item,index) => {
                    return <tr>
                      <th scope="row">{item.coinName}</th>
                      <td>
                        <a href={item.coinName.toLowerCase()+"btc" === item.tradingPair ? "https://www.huobi.com/zh-cn/exchange/"+item.coinName.toLowerCase()+"_btc": "https://www.huobi.com/zh-cn/exchange/"+item.coinName.toLowerCase()+"_usdt"} target="_blank">{item.tradingPair}</a>
                      </td>
                      <td>
                        <Button  size="sm" color="secondary" outline type="button" onClick={()=>this.handleClick(item)}>
                          {item.alarmsNum}
                        </Button>
                      </td>
                      <td>
                        {item.increase > 0?<i className="fa fa-arrow-up text-success mr-3" />:<i className="fa fa-arrow-down text-warning mr-3" />}{" "}
                        {item.increase}%
                      </td>
                      <td>
                        {item.totalIncrease > 0?<i className="fa fa-arrow-up text-success mr-3" />:<i className="fa fa-arrow-down text-warning mr-3" />}{" "}
                        {item.totalIncrease}%
                      </td>
                      <td>
                        <Button color="primary" size="sm" type="button"  color={item.tag  === 1?'success':'primary'} disabled = {item.tag  === 1?true:false}>
                          {item.tag  === 1 ?'processed':'pending'}
                        </Button>
                      </td>
                      <td>{item.alarmsTime}</td>
                      <td>{item.rank}</td>
                    </tr>
                  })}
                  </tbody>
                </Table>
              </Card>
            </Col>
            <Col xl="4">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Transaction profit</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="sm"
                      >
                        See all
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                  <tr>
                    <th scope="col">COIN-NAME</th>
                    <th scope="col">Profit</th>
                    <th scope="col">Increase</th>
                    <th scope="col">Holding</th>
                    <th scope="col">Start-Time</th>
                    <th scope="col">End-Time</th>
                  </tr>
                  </thead>
                  <tbody>
                  {this.state.profitTransactionList.map((item,index) => {
                    return <tr>
                      <th scope="row">{item.name}</th>
                      <td>{item.profit}</td>
                      <td>
                        {item.growthRate > 0?<i className="fa fa-arrow-up text-success mr-3" />:<i className="fa fa-arrow-down text-warning mr-3" />}{" "}
                        {item.growthRate}%
                      </td>
                      <td>{item.holding}%</td>
                      <td>{item.startTime}</td>
                      <td>{item.endTime}</td>
                    </tr>
                  })}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal
            className="modal-dialog-centered"
            isOpen={this.state.exampleModal}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Handling alerts
            </h5>
            <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={()=>this.exampleModalClose()}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>

          <Card className="bg-gradient-default shadow">
            <CardHeader className="bg-transparent">
              <Row className="align-items-center">
                <div className="col">
                  <h2 className="text-uppercase text-light ls-1 mb-1">
                      <span className="mb-0" >
                        <i className=" ni ni-credit-card" />{this.state.profit_new.symbol}&nbsp;&nbsp;&nbsp;
                        <i className="ni ni-sound-wave" />{this.state.profit_new.price} &nbsp;&nbsp;&nbsp;
                        {this.state.profit_new.increase > 0?<i className="fa fa-arrow-up text-success" />:<i className="fa fa-arrow-down text-warning" />}{this.state.profit_new.increase}% &nbsp;&nbsp;&nbsp;
                        <i className=" ni ni-active-40" />{this.state.profit_new.totalIncrease}%&nbsp;&nbsp;&nbsp;
                        <i className=" ni ni-bell-55" />{this.state.profit_new.alarmsNum}
                      </span>
                  </h2>
                  <h2 className="text-white mb-0">K-LINE</h2>
                </div>
                <div className="col">
                </div>
              </Row>
            </CardHeader>
            <CardBody >
              <Line data={this.state.klineList}
                    options={chartExample10.options}
                    getDatasetAtEvent={e => console.log(e)}
              />
            </CardBody>
          </Card>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
            <tr>
              <th scope="col">Coin-name</th>
              <th scope="col">Trading-pair</th>
              <th scope="col">Alert-Num</th>
              <th scope="col">Increase</th>
              <th scope="col">total-increase</th>
              <th scope="col">status</th>
              <th scope="col">alarms-Time</th>
              <th scope="col">rank</th>
            </tr>
            </thead>
            <tbody>
            {this.state.monitorInfoListOne.map((item,index) => {
              return <tr>
                <th scope="row">{item.coinName}</th>
                <td>
                  <a href={item.coinName.toLowerCase()+"btc" === item.tradingPair ? "https://www.huobi.com/zh-cn/exchange/"+item.coinName.toLowerCase()+"_btc": "https://www.huobi.com/zh-cn/exchange/"+item.coinName.toLowerCase()+"_usdt"} target="_blank">{item.tradingPair}</a>
                </td>
                <td>
                  {item.alarmsNum}
                </td>
                <td>
                  {item.increase > 0?<i className="fa fa-arrow-up text-success mr-3" />:<i className="fa fa-arrow-down text-warning mr-3" />}{" "}
                  {item.increase}%
                </td>
                <td>
                  {item.totalIncrease > 0?<i className="fa fa-arrow-up text-success mr-3" />:<i className="fa fa-arrow-down text-warning mr-3" />}{" "}
                  {item.totalIncrease}%
                </td>
                <td>
                  <Button color="primary" size="sm" type="button"  color={item.tag  === 1?'success':'primary'} disabled = {item.tag  === 1?true:false}>
                    {item.tag  === 1 ?'processed':'pending'}
                  </Button>
                </td>
                <td>{item.alarmsTime}</td>
                <td>{item.rank}</td>
              </tr>
            })}
            </tbody>
          </Table>
        </Modal>
      </>
    );
  }
}

export default Index;
