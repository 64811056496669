
import createRequest from './axios';

const request = createRequest();

request.interceptors.request.use((config) => {
  config.headers.Authorization = "xxxxxxxxxx";
  // config.headers.token = ssoAuth.token;
  return config;
});

export default request;
