
import React from 'react';
import {Line} from 'react-chartjs-2';
import {
  chartExample10,
} from "./../../variables/charts.jsx";
import { Card, CardBody, CardHeader, Col, Container, Nav, NavItem, NavLink, Row} from "reactstrap";
import classnames from "classnames";
import Header from "./../../components/Headers/Header.jsx";
import {monitorSymbol, klineList, monitorInfo} from "../../api/dashboard";


class Maps extends React.Component {
  state = {
    klineList: {
      labels: [],
      datasets: [{
        data: [],
      }],
    },
    activeNav: 2,
    coinType: '',
    symbol:[],
    period:'1day',
    profit:{
      cointName: '-',
      price: '-',
      increase:'-',
      totalIncrease: '-',
      alarmsNum: '-',
      symbol: '-'
    },
  };

  componentWillMount() {
    this.monitorSymbol();
  }

  async monitorInfo(coinType){
    const res =  await monitorInfo(coinType);
    this.setState({
      profit:res.data,
    });
  }

  async monitorSymbol(){
    const res =  await monitorSymbol();
    const symbol = [];
    res.data.map((item,index) => {
      symbol.push({coinType:item.coinType,symbol:item.symbol})
    });
    this.setState({
      symbol:symbol,
    });
    if (res.data.length > 0) {
      this.klineList(res.data[0].coinType,this.state.period)
    }
  }

  async klineList(coinType,period) {
    this.setState({
      coinType:coinType,
    });
    this.monitorInfo(coinType);
    const res =  await klineList(coinType,period);
    const klineListData = {
      labels: [],
      datasets: [
        {
          label: 'MA 5  ',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgba(245,54,92,1)',
          borderColor: 'rgba(245,54,92,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 0,
          pointHitRadius: 10,
          data: [],
        },
        {
          label: 'MA 10 ',
          fill: false,
          backgroundColor: 'rgba(75,192,192,1)',
          borderColor: 'rgba(75,192,192,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 0,
          pointHitRadius: 10,
          data: []
        },
        {
          label: 'MA 30 ',
          fill: false,
          backgroundColor: 'rgba(244,245,247,1)',
          borderColor: 'rgba(244,245,247,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 0,
          pointHitRadius: 10,
          data: []
        },
        {
          label: 'PRICE ',
          fill: true,
          backgroundColor: 'rgba(19,184,133,0.05)',
          borderColor: 'rgba(19,184,133,0.05)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(19,184,133,0.05)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(19,184,133,0.05)',
          pointHoverBorderColor: 'rgba(19,184,133,0.05)',
          pointHoverBorderWidth: 2,
          pointRadius: 0,
          pointHitRadius: 10,
          data: []
        }
      ]
    };
    res.data.map((item,index) => {
      klineListData.labels.push(item.timeTmp);
      klineListData.datasets[0].data.push(item.klineLen5);
      klineListData.datasets[1].data.push(item.klineLen10);
      klineListData.datasets[2].data.push(item.klineLen30);
      klineListData.datasets[3].data.push(item.price);
    });
    this.setState({
      klineList:klineListData,
    });
    //this.location.reload();
  }

  async handleChange(coinType) {
    this.klineList(coinType,this.state.period)
  }

  toggleNavs = (e, index) => {
    e.preventDefault();
    if (index === 1) {
      this.klineList(this.state.coinType,'4hour');
      this.setState({
        activeNav: index,
        period:"4hour"
      });
    }
    if (index === 2) {
      this.klineList(this.state.coinType,'1day');
      this.setState({
        activeNav: index,
        period:"1day"
      });
    }
  };

  render() {
    return (
        <>
          <Header />
          {/* Page content */}
          <Container className="mt--7" fluid>
            <Row>
              <Col className="mb-5 mb-xl-0" xl="12">
                <Card className="bg-gradient-default shadow">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h2 className="text-uppercase text-light ls-1 mb-1">
                          <span className="mb-0" >
                            <i className=" ni ni-credit-card" />{this.state.profit.symbol}&nbsp;&nbsp;&nbsp;
                            <i className="ni ni-sound-wave" />{this.state.profit.price} &nbsp;&nbsp;&nbsp;
                            {this.state.profit.increase > 0?<i className="fa fa-arrow-up text-success" />:<i className="fa fa-arrow-down text-warning" />}{this.state.profit.increase}% &nbsp;&nbsp;&nbsp;
                            <i className=" ni ni-active-40" />{this.state.profit.totalIncrease}%&nbsp;&nbsp;&nbsp;
                            <i className=" ni ni-bell-55" />{this.state.profit.alarmsNum}
                          </span>
                        </h2>
                        <h2 className="text-white mb-0">K-LINE</h2>
                      </div>
                      <div className="col">
                        <Nav className="justify-content-end" pills>
                          {/*<NavItem>*/}
                          {/*  <NavLink*/}
                          {/*      className={classnames("py-2 px-3", {*/}
                          {/*        active: this.state.activeNav === 1*/}
                          {/*      })}*/}
                          {/*      href="#pablo"*/}
                          {/*      onClick={e => this.toggleNavs(e, 1)}*/}
                          {/*  >*/}
                          {/*    <span className="d-none d-md-block">4 Hour</span>*/}
                          {/*    <span className="d-md-none">H</span>*/}
                          {/*  </NavLink>*/}
                          {/*</NavItem>*/}
                          <NavItem>
                            <NavLink
                                className={classnames("py-2 px-3", {
                                  active: this.state.activeNav === 2
                                })}
                                href="#pablo"
                                onClick={e => this.toggleNavs(e, 2)}
                            >
                              <span className="d-none d-md-block">one day</span>
                              <span className="d-md-none">D</span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <select
                                name="datatable-basic_length"
                                aria-controls="datatable-basic"
                                className={classnames("py-2 px-3", {
                                  active: this.state.activeNav === 2
                                })}
                                onChange={e => this.handleChange(e.target.value)}
                            >{
                              this.state.symbol.map((item,index) => {
                                return <option value={item.coinType}>{item.symbol}</option>
                              })
                            }
                            </select>
                          </NavItem>
                        </Nav>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody >
                    <Line data={this.state.klineList}
                      options={chartExample10.options}
                      getDatasetAtEvent={e => console.log(e)}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
    );
  }
}

export default Maps;
